<template>
  <div class="min-h-screen flex flex-col bg-gray-50 dark:bg-gray-900">
    <Header />

    <div class="flex-auto w-full max-w-7xl mx-auto px-2 sm:px-4">
      <RouterView v-slot="{ Component }">
        <FadeIn>
          <component :is="Component" />
        </FadeIn>
      </RouterView>
    </div>


    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import FadeIn from '@/components/transitions/FadeIn'

export default {
  components: {
    Header,
    Footer,
    FadeIn,
  }
}
</script>
