import { createRouter, createWebHashHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('../views/Home.vue')
      },
      {
        path: '/notes',
        name: 'Notes',
        component: () => import(/* webpackChunkName: "about" */ '../views/notes/index.vue'),
      },
      {
        path: '/notes/xiaogouqianqian',
        name: '小狗钱钱',
        component: () => import(/* webpackChunkName: "about" */ '../views/notes/childrenPage.vue')
      },
      {
        path: '/notes/bubble-and-catch',
        name: '事件冒泡和捕获',
        component: () => import(/* webpackChunkName: "about" */ '../views/notes/childrenPage.vue')
      },
      {
        path: '/notes/commission',
        name: '事件委托',
        component: () => import(/* webpackChunkName: "about" */ '../views/notes/childrenPage.vue')
      },
      {
        path: '/notes/cycle',
        name: '事件循环',
        component: () => import(/* webpackChunkName: "about" */ '../views/notes/childrenPage.vue')
      },
      {
        path: '/life',
        name: 'Life',
        component: () => import(/* webpackChunkName: "about" */ '../views/Life.vue')
      },
      {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
