<template>
  <div class="py-2 sm:py-4 dark:text-red-500 text-center mySelf:text-indigo-500">
    <h2>Made by zixu with ❤️</h2>
  </div>
</template>
<script>
export default {
  
}
</script>
