<template>
  <transition
    mode="out-in"
    enter-active-class="transition duration-150 ease-out"
    enter-from-class="opacity-0 transform"
    enter-to-class="opacity-100 transform"
    leave-active-class="transition duration-150 ease-in"
    leave-from-class="opacity-100 transform"
    leave-to-class="opacity-0 transform"
  >
    <slot />
  </transition>
</template>
