<template>
  <div class="p-3 sm:p-4">
    <div class="flex justify-between">
      <RouterLink to='/' class="dark:text-gray-50">zixu -- 知行合一</RouterLink>
      <div class="flex space-x-4">
        <ul class="flex space-x-4">
          <li>
            <RouterLink
              to="/notes"
              v-slot="{ href, route, navigate, isActive }"
              custom
            >
              <a :href="href" :class="[ isActive ? 'text-yellow-500' : 'dark:text-gray-50' ]" @click="navigate">{{ route.name }}</a>
            </RouterLink>
          </li>
          <li>
            <RouterLink
              to="/life"
              v-slot="{ href, route, navigate, isActive }"
              custom
            >
              <a :href="href" :class="[ isActive ? 'text-yellow-500' : 'dark:text-gray-50' ]" @click="navigate">{{ route.name }}</a>
            </RouterLink>
          </li>
          <li>
            <RouterLink
              to="/about"
              v-slot="{ href, route, navigate, isActive }"
              custom
            >
              <a :href="href" :class="[ isActive ? 'text-yellow-500' : 'dark:text-gray-50' ]" @click="navigate">{{ route.name }}</a>
            </RouterLink>
          </li>
          
        </ul>
        <Toggle :model-value="enabled" @click="changeDarkMode" />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'

import Toggle from '@/components/forms/Toggle.vue'

export default {
  components: {
    Toggle,
  },

  setup () {
    const state = reactive({
      enabled: false,
    })

    function changeDarkMode () {
      state.enabled = !state.enabled
      const html = document.querySelector('html')

      if (state.enabled) {
        html.classList.add('dark')
      } else {
        html.classList.remove('dark')
      }
    }

    return {
      ...toRefs(state),
      changeDarkMode,
    }
  }
}
</script>
